
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getVersionDetail, getVersionByName, getPlistFile, downloadCount } from '@/api/version';
import { getAppItemsDetail } from '@/api/items';
import VueQr from 'vue-qr';
import moment from 'moment';
import { StyleValue } from 'vue';

type IVersion = IResponse.Version.IVersion;

const versionDetail = {
    CFBundleIdentifier: "",
    CFBundleVersion: "",
    create_time: "",
    description: null,
    icon: "",
    id: "",
    items_id: "",
    plist: "",
    requisite: 0,
    src: "",
    type: "",
    update_time: "",
    versions_number: "",
    App_Store: null,
    ExpirationDate: "",
    userGroupId: ""
}

enum SystemType {
    Ios = 0,
    Android = 1,
    Mac = 2,
    Window = 3
}

@Component({
    components: {
        VueQr
    }
})
export default class AppPreView extends Vue {
    public phoneBottomStyle: StyleValue = { paddingBottom: innerHeight - 1196 + 'px' }
    public isIphoneNotSafari: boolean = false
    public open: boolean = false
    public information: string = 'For install, Please tap top right corner in Wechat, then tap open in Browser in the pop menu.'
    public buttonLoading: boolean = false
    public installMsgShow: boolean = false
    public installMsg: string = ""
    public href: string = window.location.href
    public appName: string = ''
    public versionDetail: IVersion = versionDetail
    public isPhone: boolean = false
    public type: string = ''

    public created(): void {
        this.getShortUrl()
    }
    public mounted(): void {
        const isIosPhone = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        // 判断是否是手机设备
        if (isIosPhone || this.isAndroid) {
            this.isPhone = true;
        } else {
            this.isPhone = false;
        }
        if (isIosPhone && !this.isSafari) {
            this.isIphoneNotSafari = true;
        } else {
            this.isIphoneNotSafari = false;
        }
        // 判断是否为微信浏览器
        this.slideDown();
    }

    // 统计下载次数
    public downloadCountEvent(): void {
        downloadCount({
            versionId: this.versionDetail.id,
            itemId: this.versionDetail.items_id
        });
    }
    public download(): void {
        if (this.platform === SystemType.Android || this.platform === SystemType.Mac || this.platform === SystemType.Window) { // Android下载apk
            const a = document.createElement('a');
            const installUrl = this.versionDetail.src;
            a.setAttribute('href', installUrl);
            this.buttonLoading = true;
            a.click();
            setTimeout(() => {
                this.buttonLoading = false;
                this.installMsgShow = true;
                this.installMsg = "Downloading, please check the download progress in browser"
            }, 6000);
            this.downloadCountEvent();
        } else if (this.platform === SystemType.Ios && this.isIos) { // iPhone 或 iPad下载ipa
            const a = document.createElement('a');
            if (this.versionDetail.App_Store) {
                const appStoreLink = this.versionDetail.App_Store;
                a.setAttribute('href', appStoreLink);
                a.click();
                return;
            }
            this.buttonLoading = true;
            const expirationDate = new Date(this.versionDetail.ExpirationDate);
            const plistUrl = this.versionDetail.plist;
            const installUrl = `itms-services://?action=download-manifest&url=${plistUrl}`;
            a.setAttribute('href', installUrl);
            if (this.versionDetail.ExpirationDate && expirationDate < new Date()) {
                const that = this;
                this.$confirm({
                    title: 'This app has expired. Are you sure to install it?',
                    onOk() {
                        getPlistFile(that.versionDetail.id).then((res) => {
                            a.click();
                            setTimeout(() => {
                                that.buttonLoading = false;
                                that.installMsgShow = true;
                                that.installMsg = "Downloading, please return to the system desktop to check the download progress"
                            }, 6000);
                            that.downloadCountEvent();
                        })
                            .catch((err) => {
                                that.buttonLoading = false;
                                that.installMsgShow = true;
                                that.installMsg = "Installation failed with unknown error"
                                console.log(err);
                            });
                    },
                    onCancel() {
                        that.buttonLoading = false
                        console.log('Cancel');
                    },
                });
            } else {
                a.click();
                setTimeout(() => {
                    this.buttonLoading = false;
                    this.installMsgShow = true;
                    this.installMsg = "Downloading, please return to the system desktop to check the download progress"
                }, 6000);
                this.downloadCountEvent();
            }
        } else if ((this.platform === SystemType.Android && this.isIos) || (this.platform === SystemType.Ios && this.isAndroid)) { // 系统不匹配
            if (this.platform === SystemType.Android) {
                alert('This app is for Android!')
            } else if (this.platform === SystemType.Ios) {
                alert('This app is for Ios!')
            }
        } else { // PC端下载
            this.$message.destroy();
            if (this.platform === SystemType.Android) {
                this.$message.error('Please open it on Android mobile phone!');
            } else if (this.platform === SystemType.Ios) {
                this.$message.error('Please open it on iPhone!');
            }
        }
    }
    public async initVersionById(id: string): Promise<void> {
        const response = await getVersionDetail(id);
        this.versionDetail = response.data;
        this.initAppName(this.versionDetail.items_id);
    }
    public async initVersionByUrl(name: string): Promise<void> {
        const response = await getVersionByName(name);
        this.versionDetail = response.data;
        this.initAppName(this.versionDetail.items_id);
    }
    public async initAppName(itemId: string): Promise<void> {
        const response = await getAppItemsDetail(itemId);
        this.appName = response.data.items_name;
        this.type = response.data.type
        this.buttonLoading = false;
    }
    public slideDown(): void {
        if (this.isWeChat) {
            this.information = 'For install, Please tap top right corner in Wechat, then tap open in Browser in the pop menu.'
            const that = this;
            setTimeout(function () {
                that.open = true
            }, 200)
        } else if (this.isIphoneNotSafari) {
            this.information = 'For install, please open it in Safari browser, then you can install your App.'
            const that = this;
            setTimeout(function () {
                that.open = true
            }, 200)
        }
    }
    /** 是否PC */
    get isPC(): boolean {
        return (this.type === '2' || this.type === '3');
    }
    /** 下载按钮文本 */
    get downloadButtonText(): string {
        if (this.isPC) {
            return 'Download Now';
        } else {
            return 'Install App';
        }
    }

    public convertTime(time: string): string {
        return moment(time).format('YYYY/MM/DD HH:mm');
    }
    public isGuidFormat(guid: string): boolean {
        const reg = new RegExp(/^[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}$/);
        if (reg.test(guid)) {
            return true;
        }
        return false;
    }
    /** 检查是否iPhone */
    get isIos(): boolean {
        const u = navigator.userAgent;
        // const isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        return /iPhone|iPad|iPod|Mac OS/.test(u);
    }
    /** 检查是否android */
    get isAndroid(): boolean{
        const u = navigator.userAgent;
        return !!(u.match(/(Android)\s+([\d.]+)/));
    }
    /** 平台文本转换 */
    get platformText(): string {
        switch (this.platform) {
            case SystemType.Ios: {
                return 'iPhone';
            }
            case SystemType.Android: {
                return 'Android';
            }
            case SystemType.Mac: {
                return 'Mac';
            }
            case SystemType.Window:
            default:
                return 'Window';
        }
    }
    public get isWeChat(): boolean { // 判断微信浏览器
        const ua: string = window.navigator.userAgent.toLowerCase();
        if (ua.indexOf('micromessenger') !== -1) {
            return true;
        } else {
            return false;
        }
    }
    public get isSafari(): boolean { // 判断Safari浏览器
        const isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
        if (isSafari) {
            return true;
        } else {
            return false;
        }
    }
    public get platform(): number {
        switch (this.type) {
            case "0": {
                return SystemType.Ios
            }
            case "1": {
                return SystemType.Android
            }
            case "2": {
                return SystemType.Mac
            }
            case "3":
            default:
                return SystemType.Window

        }
    }

    @Watch('$route')
    public getShortUrl() {
        this.href = window.location.href;
        let hash = window.location.hash;
        hash = hash.replace(/\#\//g, '');
        if (!hash) {
            this.$message.error('Id or short url is null!');
            return;
        }
        const isGuid = this.isGuidFormat(hash);
        this.buttonLoading = true;
        if (isGuid) {
            this.initVersionById(hash as string);
        } else {
            this.initVersionByUrl(hash as string);
        }
    }
}
